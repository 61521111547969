<template>
  <div class="box" style="margin-bottom: 0; border-radius: 0">
    {{ index + 1 }}. {{ data.name }}
    <!-- <a class="panel-block">
      <span class="panel-icon">
        <i
          class=""
          aria-hidden="true"
          :class="{
            'has-text-info fas fa-user': data.isPriority,
            'far fa-user': !data.isPriority
          }"
        ></i>
      </span>
      {{ index + 1 }}. {{ data.name }}
    </a> -->
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    index: null
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .product-link {
    color: $vue-color;
  }

  .delete-btn {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    margin-left: 10px;
    color: $danger-color;
    border-color: $danger-color;
  }

  .box:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
</style>
