<template>
  <div class="columns is-centered is-mobile is-multiline is-1">
    <div class="field column is-full">
      <h2 class="title">{{ `🏢 ${locationName} `}}</h2>
      <h3 class="subtitle">Check-in e Fila</h3>

      <transition name="fade">
        <b-message
          v-show="serviceStatus === 'waiting'"
          type="is-info"
          aria-close-label="Close message"
          size="is-small"
        >
          Você já está inserido na fila, navega para a aba Ver Fila para mais
          opções
        </b-message>
      </transition>
      <nav class="panel is-info">
        <div class="tabs is-fullwidth">
          <ul>
            <li
              :class="{
                'is-active': tabState === 1,
              }"
              @click="setTabState(1)"
            >
              <a>
                <span class="icon"
                  ><i class="fas fa-angle-down" aria-hidden="true"></i
                ></span>
                <span>Check-in</span>
              </a>
            </li>

            <li
              :class="{
                'is-active': tabState === 2,
              }"
              @click="setTabState(2)"
            >
              <a>
                <span>A fila</span>
                <span style="margin-left: 5px">({{ actualPosition }}) </span>
                <span class="icon"
                  ><i class="fas fa-angle-down" aria-hidden="true"></i
                ></span>
              </a>
            </li>
          </ul>
        </div>

        <div v-show="tabState === 1">
          <costumer-add
            :service-status="serviceStatus"
            :tab-state="tabState"
          ></costumer-add>
        </div>
        <transition name="fade">
          <div v-show="tabState === 2">
            <nav v-show="serviceStatus === 'waiting'" class="level">
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Posição Atual</p>
                  <p class="title has-text-warning">{{ actualPosition }}</p>
                </div>
              </div>
            </nav>

            <queue-list-costumer
              v-show="false"
              :actual-user="costumerPhone"
              :service-status="serviceStatus"
            ></queue-list-costumer>

            <!-- <h6 v-show="serviceStatus === 'waiting'" style="padding: 5px">
              Ações disponíveis para interagir com a fila:
            </h6> -->

            <transition name="fade">
              <div v-if="serviceStatus === 'onService'" class="column is-full">
                <h5 class="has-text-centered">Você está em atendimento</h5>
                <figure class="image container is-256x256">
                  <img
                    alt="qr-code is-block"
                    src="@/assets/img/clock-undraw.svg"
                  />
                </figure>
              </div>
            </transition>

            <div v-if="serviceStatus === 'served'">
              <rating :location-id="locationId"></rating>
            </div>

            <div
              v-if="
                serviceStatus !== 'onService' &&
                  serviceStatus !== 'served' &&
                  serviceStatus !== null
              "
              class="panel-block"
            >
              <button
                class=" button is-info is-fullwidth  "
                @click="
                  updateCostumerOnQueue(['costumersOnService', costumerPhone])
                "
              >
                Comecei a ser atendido!
              </button>
            </div>
            <div
              v-if="
                serviceStatus !== 'served' &&
                  serviceStatus !== 'waiting' &&
                  serviceStatus !== null
              "
              class="panel-block"
            >
              <button
                class="button is-primary is-fullwidth"
                @click="
                  updateCostumerOnQueue(['costumersServed', costumerPhone])
                "
              >
                Finalizei o atendimento
              </button>
            </div>

            <div
              v-if="
                serviceStatus !== 'served' &&
                  serviceStatus !== null &&
                  serviceStatus !== 'onService'
              "
              class="panel-block"
            >
              <button
                class="button is-danger  is-fullwidth"
                @click="updateCostumerOnQueue(['exited', costumerPhone])"
              >
                Desistir da fila
              </button>
            </div>
          </div>
        </transition>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";

import "firebase/firestore";

import router from "../../router";
import QueueListCostumer from "@/components/queue/QueueListCostumer";
import Rating from "@/components/costumer/rating/Rating";
import CostumerAdd from "@/components/costumer/CostumerAdd";

export default {
  name: "QueueListCostumerView",
  components: { QueueListCostumer, Rating, CostumerAdd },
  computed: {
    ...mapState("costumers", [
      "productCreationPending",
      "locationId",
      "locationName",
      "costumerName",
      "costumerPhone",
      "costumerIsPriority",
      "tabState",
      "checked",
      "costumerIsInQueue",
      "serviceStatus",
      "checkIfUserIsActiveOnQueue",
      "rating",
      "message",
      "actualPosition",
      "newClientIdAfterFinished",
    ]),
  },
  watch: {
    serviceStatus: {
      handler() {
        console.log("hadler");
      },
      immediate: true,
    },
  },
  created() {
    // Check if user has ID assigned on URL
    // this.$store.commit('app/setCheckUrl', true)
    this.$store.commit("costumer/setLocationName", router.currentRoute.params.locationName)
    this.checkForUserFromUrlParam();
  },
  beforeMount() {},
  beforeDestroy() {
    // this.$store.commit('app/setCheckUrl', false)
  },
  methods: {
    ...mapMutations("costumers", [
      "setLocationId",
      "setCostumerName",
      "setCostumerIsPriority",
      "setTabState",
      "setChecked",
      "setCostumerIsInQueue",
      "setRating",
      "setRatingMessage",
      "setActualPosition",
      "setNewClientIdAfterFinished",
    ]),
    ...mapActions("costumers", [
      "triggerAddProductAction",
      "checkLocalstorage",
      "updateCostumerOnQueue",
      "setCostumerRating",
    ]),
    ...mapActions("costumers", ["getCostumersQty", "getCostumersQtyOnService"]),
    ...mapActions("queue", ["initializeQueue", router.currentRoute.params.id]),
    checkForUserFromUrlParam() {
      
      if (router.currentRoute.params.phone !== "null") {
        this.$store.commit(
          "costumers/setCostumerPhone",
          router.currentRoute.params.phone
        );
        this.$store.commit(
          "costumers/setNewClientIdAfterFinished",
          router.currentRoute.params.phone
        );
        this.$store.commit(
          "costumers/setLocationId",
          router.currentRoute.params.id
        );
        this.$store.dispatch("costumers/checkIfUserIsActiveOnQueue", [
          this.locationId,
          this.costumerPhone
        ]);
      } else if (this.costumerPhone !== "null") {
        this.$store.dispatch("costumers/checkIfUserIsActiveOnQueue", [
          this.locationId,
          this.costumerPhone
        ]);
      }
    },
    data() {
      return {
        rating: "No Rating Selected",
        currentRating: "No Rating",
        currentSelectedRating: "No Current Rating",
        boundRating: 3
      };
    },
  },
};
</script>
<style>
.panel-block {
  padding: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
