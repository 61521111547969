var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-centered is-mobile is-multiline is-1"},[_c('div',{staticClass:"field column is-full"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(("🏢 " + _vm.locationName + " ")))]),_c('h3',{staticClass:"subtitle"},[_vm._v("Check-in e Fila")]),_c('transition',{attrs:{"name":"fade"}},[_c('b-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.serviceStatus === 'waiting'),expression:"serviceStatus === 'waiting'"}],attrs:{"type":"is-info","aria-close-label":"Close message","size":"is-small"}},[_vm._v(" Você já está inserido na fila, navega para a aba Ver Fila para mais opções ")])],1),_c('nav',{staticClass:"panel is-info"},[_c('div',{staticClass:"tabs is-fullwidth"},[_c('ul',[_c('li',{class:{
              'is-active': _vm.tabState === 1,
            },on:{"click":function($event){return _vm.setTabState(1)}}},[_vm._m(0)]),_c('li',{class:{
              'is-active': _vm.tabState === 2,
            },on:{"click":function($event){return _vm.setTabState(2)}}},[_c('a',[_c('span',[_vm._v("A fila")]),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("("+_vm._s(_vm.actualPosition)+") ")]),_vm._m(1)])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabState === 1),expression:"tabState === 1"}]},[_c('costumer-add',{attrs:{"service-status":_vm.serviceStatus,"tab-state":_vm.tabState}})],1),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabState === 2),expression:"tabState === 2"}]},[_c('nav',{directives:[{name:"show",rawName:"v-show",value:(_vm.serviceStatus === 'waiting'),expression:"serviceStatus === 'waiting'"}],staticClass:"level"},[_c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Posição Atual")]),_c('p',{staticClass:"title has-text-warning"},[_vm._v(_vm._s(_vm.actualPosition))])])])]),_c('queue-list-costumer',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"actual-user":_vm.costumerPhone,"service-status":_vm.serviceStatus}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.serviceStatus === 'onService')?_c('div',{staticClass:"column is-full"},[_c('h5',{staticClass:"has-text-centered"},[_vm._v("Você está em atendimento")]),_c('figure',{staticClass:"image container is-256x256"},[_c('img',{attrs:{"alt":"qr-code is-block","src":require("@/assets/img/clock-undraw.svg")}})])]):_vm._e()]),(_vm.serviceStatus === 'served')?_c('div',[_c('rating',{attrs:{"location-id":_vm.locationId}})],1):_vm._e(),(
              _vm.serviceStatus !== 'onService' &&
                _vm.serviceStatus !== 'served' &&
                _vm.serviceStatus !== null
            )?_c('div',{staticClass:"panel-block"},[_c('button',{staticClass:" button is-info is-fullwidth  ",on:{"click":function($event){return _vm.updateCostumerOnQueue(['costumersOnService', _vm.costumerPhone])}}},[_vm._v(" Comecei a ser atendido! ")])]):_vm._e(),(
              _vm.serviceStatus !== 'served' &&
                _vm.serviceStatus !== 'waiting' &&
                _vm.serviceStatus !== null
            )?_c('div',{staticClass:"panel-block"},[_c('button',{staticClass:"button is-primary is-fullwidth",on:{"click":function($event){return _vm.updateCostumerOnQueue(['costumersServed', _vm.costumerPhone])}}},[_vm._v(" Finalizei o atendimento ")])]):_vm._e(),(
              _vm.serviceStatus !== 'served' &&
                _vm.serviceStatus !== null &&
                _vm.serviceStatus !== 'onService'
            )?_c('div',{staticClass:"panel-block"},[_c('button',{staticClass:"button is-danger  is-fullwidth",on:{"click":function($event){return _vm.updateCostumerOnQueue(['exited', _vm.costumerPhone])}}},[_vm._v(" Desistir da fila ")])]):_vm._e()],1)])],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('span',[_vm._v("Check-in")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-angle-down",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }