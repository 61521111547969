<template>
  <div class="page-wrapper">
    <costumer-check-in
      v-if="networkOnLine"
      style="margin-top: 15px"
    ></costumer-check-in>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CostumerCheckIn from "@/components/costumer/CostumerCheckIn";

export default {
  components: { CostumerCheckIn },
  data() {
    return { storeName: null };
  },
  computed: mapState("app", ["networkOnLine"]),
  created() {
    this.$store.commit('app/setCostumerCheckIn', true);
  },
  destroyed() {
    this.$store.commit('app/setCostumerCheckIn', false);
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/variables.scss";

.products-page-title {
  text-align: center;
  margin-bottom: 60px;
}

.store-name {
  text-align: center;
}

.product-list {
  margin: 20px 0;
}
</style>
