<template>
  <div>
    <queue-item-costumer
      v-for="(q, ind) in queue"
      v-show="q.phoneNumber === actualUser || serviceStatus === null"
      :key="q.costumerPhone"
      v-model="queue"
      class="has-centered-text"
      :index="ind"
      :data="q"
      :actual-user="costumerPhone"
    ></queue-item-costumer>
    <!-- <queue-item-costumer-on-service
      v-for="(q, ind) in queue"
      v-show="q.phoneNumber === actualUser || serviceStatus === null"
      :key="q.costumerPhone"
      v-model="queue"
      class="has-centered-text"
      :index="ind"
      :data="q"
      :actual-user="costumerPhone"
    ></queue-item-costumer-on-service> -->
  </div>
</template>

<script>
import QueueItemCostumer from '@/components/queue/QueueItemCostumer'
// import QueueItemCostumerOnService from '@/components/queue/QueueItemCostumerOnService'

import { mapState, mapMutations, mapActions } from 'vuex'
import firebase from 'firebase/app'
import router from '../../router'

export default {
  name: 'QueueListCostumerView',
  components: {
    QueueItemCostumer
  },
  props: {
    data: Object,
    index: Number,
    isProductDeletionPending: Boolean,
    disableActions: Boolean,
    actualUser: String,
    serviceStatus: String
  },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('costumers', ['costumerPhone', 'newClientIdAfterFinished']),
    ...mapState('queue', ['queue', 'queueOnService']),
    ...mapMutations('queue', ['setQueue']),
    ...mapMutations('costumers', ['setServiceStatus'])
  },
  created() {
    this.initialValues(router.currentRoute.params.id, 'costumers')
    this.initialValues(router.currentRoute.params.id, 'costumersOnService')
  },
  methods: {
    ...mapActions('queue', ['initializeQueue']),
    async initialValues(locationId, status) {
      firebase
        .firestore()
        .collection('locations')
        .doc(locationId)
        .collection(status)
        .orderBy('createTimestamp')
        .onSnapshot(snapshot => {
          const response = snapshot.docs.map(doc => doc.data())

          const { size } = snapshot
          if (status === 'costumers') {
            this.$store.commit('queue/setQueue', response)
            this.$store.commit('costumers/setQtyCostumers', size)
          }

          this.$store.commit('queue/setQueueOnService', response)

          this.checkPositionOnQueue()
        })
    },
    checkPositionOnQueue() {
      let a = this.queue.concat(this.queueOnService)

      for (let i = 0; i < this.queueOnService.length; i += 1) {
        if (
          this.queueOnService[i] &&
          this.queueOnService[i].phoneNumber === this.actualUser
        ) {
          a = true
          this.$store.commit('costumers/setServiceStatus', 'onService')
        }
      }
      for (let i = 0; i < this.queue.length; i += 1) {
        const b = i + 1
        if (this.queue[i] && this.queue[i].phoneNumber === this.actualUser) {
          this.$store.commit('costumers/setServiceStatus', 'waiting')
          this.$store.commit('costumers/setActualPosition', b)
        }
      }

      for (let i = 0; i < a.length; i += 1) {
        if (
          this.queueOnService[i] &&
          this.queueOnService[i].phoneNumber !== this.actualUser &&
          this.queue[i] &&
          this.queue[i].phoneNumber !== this.actualUser &&
          this.newClientIdAfterFinished === null
        ) {
          console.log(this.newClientIdAfterFinished)
          this.$store.commit('costumers/setServiceStatus', null)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.infos-label {
  text-align: center;
}

.product-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  justify-content: space-between;
}
</style>
