<template>
  <div>
    <div class="columns">
      <div class="column">
        <h5 class="subtitle has-text-centered has-text-info">
          Obrigado por concluir o Atendimento
        </h5>
        <figure class="image container" style="width: 50%;">
          <img
            alt="qr-code is-block"
            src="@/assets/img/confirmation-undraw.svg"
          />
        </figure>
      </div>
    </div>
    <div class="columns is-centered" style="text-align: -webkit-center;">
      <div class="column is-6">
        <star-rating
          style="display: -webkit-inline-box;"
          :star-size="40"
          :show-rating="false"
          :increment="0.5"
          @current-rating="showCurrentRating"
          @rating-selected="setCurrentSelectedRating"
        ></star-rating>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-10">
        <b-field label="Comentário">
          <b-input
            :input="ratingMessage"
            maxlength="200"
            type="textarea"
            @input="setRatingMessage($event)"
          ></b-input>
        </b-field>
      </div>
    </div>
    <div class="panel-block">
      <button
        :disabled="ratingSent"
        class="button is-info is-outlined is-fullwidth"
        @click="finishAndSendRating([rating, ratingMessage])"
      >
        <span v-if="!ratingSent">Avaliar o atendimento</span>
        <span v-else>Avalição recebida, obrigado!</span>
      </button>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  components: { StarRating },
  props: {
    product: Object,
    newClientIdAfterFinished: String
  },
  computed: {
    ...mapState('costumers', [
      'ratingMessage',
      'rating',
      'locationId',
      'newClientIdAfterFinished'
    ]),
    ...mapState('locations', ['ratingSent'])
  },
  methods: {
    ...mapMutations('costumers', ['setLocationId', 'setRating']),
    ...mapActions('locations', ['getAvgRating']),
    ...mapMutations('locations', ['setRatingMessage']),
    setRating(rating) {
      this.rating = `You have Selected: ${rating} stars`
      this.$store.commit('costumers/setRating', rating)
    },
    showCurrentRating(rating) {
      this.currentRating =
        rating === 0
          ? this.currentSelectedRating
          : `Click to select ${rating} stars`
    },
    setCurrentSelectedRating(rating) {
      this.$store.commit('costumers/setRating', rating)
    },
    setRatingMessage(message) {
      this.$store.commit('costumers/setRatingMessage', message)
    },
    finishAndSendRating(rating) {
      this.$store
        .dispatch('costumers/setCostumerRating', [
          rating,
          this.ratingMessage,
          this.locationId
        ])
        .then(() => {
          this.$buefy.toast.open(
            'Sua avalição foi recebiba com sucesso, obrigado!'
          )

          this.$store.dispatch('locations/getAvgRating', [
            this.locationId,
            rating
          ])
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.product-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
