<template>
  <div class="v-step-12">
    <div class="field column is-full">
      <div class="control">
        <label>
          Seu nome
        </label>
        <input
          :disabled="serviceStatus !== null"
          placeholder="Seu nome"
          class="input is-primary"
          type="text"
          :value="costumerName"
          @input="setCostumerName($event.target.value)"
        />
      </div>
    </div>
    <div class="field column is-full">
      <div class="control">
        <label>
          Seu telefone
        </label>
        <input
          v-mask="'(##) #####-####'"
          :disabled="serviceStatus !== null"
          class="input"
          type="tel"
          :value="costumerPhone"
          placeholder="Seu telefone"
          @input="evt => setCostumerPhone(evt.target.value.replace(/\D+/g, ''))"
        />
      </div>
    </div>

    <div class="field column is-full">
      <div class="control ">
        <label
          class="checkbox button is-fullwidth is-info is-outlined"
          :disabled="serviceStatus === 'waiting'"
        >
          <input
            v-model="costumerIsPriority"
            type="checkbox"
            @change="setCostumerIsPriority($event.target.checked)"
          />
          <span style="margin-left: 5px">
            Atendimento preferencial?
          </span>
        </label>
      </div>
    </div>

    <div class="field column is-full is-centered">
      <button
        :disabled="serviceStatus === 'waiting'"
        class="button is-primary has-text-centered is-fullwidth"
        @click="confirm"
      >
        <span class="icon is-small">
          <i class="fas fa-check"></i>
        </span>
        <span v-show="serviceStatus !== 'waiting'">Entrar na fila virtual</span>
        <span v-show="serviceStatus === 'waiting'"
          >Você já está nessa fila</span
        >
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import router from "../../router";

export default {
  directives: { mask },
  props: {
    serviceStatus: String,
    resetAfter: Boolean
  },
  computed: {
    ...mapState("costumers", [
      "productCreationPending",
      "locationId",
      "costumerName",
      "costumerPhone",
      "costumerIsPriority",
      "checked",
      "costumerIsInQueue",
      "checkIfUserIsActiveOnQueue"
    ])
  },
  created() {
    if (!this.resetAfter) {
      // Get localstorage
      this.$store.dispatch("costumers/checkLocalstorage");
    }
    // Get Location ID from URL
    this.setLocationId();
  },
  methods: {
    ...mapMutations("costumers", [
      "setLocationId",
      "setCostumerName",
      "setCostumerIsPriority",
      "setCostumerPhone",
      "setChecked",
      "setCostumerIsInQueue"
    ]),
    ...mapActions("costumers", [
      "triggerAddProductAction",
      "checkLocalstorage",
      "setCostumerIsPriority"
    ]),
    setCostumerIsPriority(costumerIsPriority) {
      this.$store.commit("costumers/setCostumerIsPriority", costumerIsPriority);
    },
    confirm() {
      this.$buefy.dialog.confirm({
        message: "Você deseja entrar na fila virtual?",
        type: "is-primary",
        onConfirm: () => {
          this.$store.dispatch("costumers/triggerAddProductAction").then(() => {
            if (this.resetAfter) {
              this.$store.commit("costumers/setCostumerName", null);
              this.$store.commit("costumers/setCostumerPhone", null);
              this.$store.commit("costumers/setCostumerIsPriority", false);
            }
            this.$buefy.toast.open({
              type: "is-info",
              message: "Você entrou na fila"
            });
          });
        }
      });
    },
    setLocationId() {
      this.$store.commit(
        "costumers/setLocationId",
        router.currentRoute.params.id
      );

      this.$store.commit(
        "costumers/setLocationName",
        router.currentRoute.params.locationName
      );
    }
  }
};
</script>
